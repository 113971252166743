import styled from 'styled-components'
import colors from '../../assets/style/colors'
import BackImage from '../../assets/images/back.jpg'

const ReportOnCollectors = styled.div`
	min-height: 100vh;
	padding: 20px;
	padding-top: 30px;
	// background-image: url(${BackImage});
	background-size: cover;
	background-position: center center;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: shrink;
	gap: 8px;

	.loader {
		margin-top: 150px;
		margin-left: 50%;
	}

	.container {
		padding: 20px;
		box-shadow: 3px 3px 10px #cfcfcf;
		border-radius: 10px;
		background-color: #fffffff2;
		min-height: 92vh;

		.topCard {
			display: flex;
			justify-content: space-between;
			padding: 0 20px;
			margin-top: 10px;

			.flex {
				display: flex;
				width: 80px;
				justify-content: space-between;
			}

			.btn {
				align-items: center;
				width: 35px;
				height: 35px;
				padding: 0;
				border-radius: 50px;
			}
		}

		.paginationContainer {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 8px;

			button {
				border: 1px solid gray;
				border-radius: 6px;
				padding: 4px 12px;
			}

			button:hover {
				background-color: lightgray;
			}

			button:disabled {
				border: 1px solid #999999;
				background-color: #cccccc;
				color: #666666;
			}
		}

		.table {
			border-collapse: collapse;
			margin-top: 20px;
			background-color: #ffffff;
			width: 100%;

			td,
			th {
				border: 1px solid ${colors.gray};
				padding: 5px;
			}

			.row {
				background-color: #ffffff;
			}

			.row:hover {
				background-color: #f0f4f5;
				cursor: pointer;
			}
		}

		.searchCard {
			display: flex;
			flex-direction: column;
			gap: 10px;
			justify-content: space-between;
			margin-bottom: 10px;
			align-items: end;

			.input {
				border: 2px solid ${colors.gray};
				font-size: 14px;
				padding: 10px;
				border-radius: 5px;
				color: #434343;
				width: 280px;
				margin-bottom: 20px;
			}
		}

		.productTitle {
			font-size: 25px;
			font-weight: bold;
			color: #434343;
			padding: 0;
			margin: 0;
			text-align: center;
		}

		.inputContainer {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: start;
			gap: 12px;

			.inputWrapper {
				display: flex;
				flex-direction: column;
				gap: 4px;
			}

			.searchButton {
				padding: 13px;
				border: 1px solid gray;
				border-radius: 8px;
				cursor: pointer;
				margin-top: 30px;
			}

			.searchButton:hover {
				background-color: lightgray;
			}
		}

		.chartsContainer {
			display: flex;
			flex-direction: column;
			gap: 16px;

			.topChart {
				width: 70%;
				height: fit-content;
				border-radius: 8px;
				border: 1px solid lightgray;
				margin: 0 auto;
				padding: 8px;
			}

			.bottomCharts {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 12px;

				.chartCard {
					width: 100%;
					height: 240px;
					border-radius: 8px;
					border: 1px solid lightgray;
					padding: 8px;
				}
			}
		}
	}
`
export default ReportOnCollectors
