export const generateUrlParams = (baseUrl, params) => {
	const urlParams = new URLSearchParams()

	Object.entries(params).forEach(([key, value]) => {
		if (value !== '' && value !== null && value !== undefined) {
			urlParams.append(key, value.toString())
		}
	})

	return `${baseUrl}?${urlParams.toString()}`
}
